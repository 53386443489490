/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_ACTIVE_STREAM,
    SET_STREAM_LIST,
} from "../actions/stream/types";

/*
 * ---------------------------------------------------------------------
 * initial event state
 * ---------------------------------------------------------------------
 */
// Till now the event is null maybe in future we change this behaviour
const initialState = {
    // event core features mandatory
    active: null,
    list: [],
};


/*
 * ---------------------------------------------------------------------
 * stream reducer
 * ---------------------------------------------------------------------
 */
const stream = (state = initialState, action) => {
    switch (action.type) {
        case SET_STREAM_LIST:
            return Object.assign({}, state, {
                list: action.payload,
            });

        case SET_ACTIVE_STREAM:
            return Object.assign({}, state, {
                active: action.payload,
            });

        default:
            return state;
    }
};

export default stream;
