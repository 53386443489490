/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
import {
    SET_PLAYER,
} from "src/shared_modules/veu-visitors-core/actions/player/types";
/*
 * ---------------------------------------------------------------------
 * initial player state
 * ---------------------------------------------------------------------
 */
const initialState = {
    path: null,
    version: "v2",
};
/*
 * ---------------------------------------------------------------------
 * player reducer
 * ---------------------------------------------------------------------
 */
const player = (state = initialState, action) => {
    switch (action.type) {

        case SET_PLAYER:
            let path = null;
            let version = "v2";

            if (action.payload){
                path = action.payload.path;
                version = action.payload.version
            }
            
            return Object.assign({}, state, {
                path: path,
                version : version
            });
        default:
            return state;
    }
};

export default player;
