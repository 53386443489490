/*
 * ---------------------------------------------------------------------
 * System Action Types
 * ---------------------------------------------------------------------
 */
export const SHOW_WALLOFIDEAS = "SHOW_WALLOFIDEAS";
export const HIDE_WALLOFIDEAS = "HIDE_WALLOFIDEAS";

export const SET_PLAZZ_ACTIVE_WALL = "SET_PLAZZ_ACTIVE_WALL";
export const SET_PLAZZ_WALLOFIDEAS_POSTS = "SET_PLAZZ_WALLOFIDEAS_POSTS";
