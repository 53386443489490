/*
 * ---------------------------------------------------------------------
 * import axios global config
 * ---------------------------------------------------------------------
 * env dependen config for using the correct backend url
 */
import axios from "axios";

const AXIOS_DEV = {
    timeout: 0,
    veu: {
        baseUrl: "/backend/",
        //baseUrl: "http://localhost:4000/backend/",
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'VEU-MODULE': 'visitors',
            'VEU-API': 'c62952f9-747f-484d-8fef-63dbef15d705',
        }
    },
};

const AXIOS_TEST = {
    timeout: 0,
    veu: {
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'VEU-MODULE': 'visitors',
            'VEU-API': 'c62952f9-747f-484d-8fef-63dbef15d705',
        }
    },
};

const AXIOS_STAGE = {
    timeout: 0,
    veu: {
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'VEU-MODULE': 'visitors',
            'VEU-API': 'c62952f9-747f-484d-8fef-63dbef15d705',
        }
    },
};

const AXIOS_LIVE = {
    timeout: 0,
    veu: {
        baseUrl: process.env.REACT_APP_BACKEND_URL,
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'VEU-MODULE': 'visitors',
            'VEU-API': 'c62952f9-747f-484d-8fef-63dbef15d705',
        }
    },
};


export const getConfig = () => {
    if (process.env.REACT_APP_DEPLOY_ENV === "development")
        return AXIOS_DEV;

    if (process.env.REACT_APP_DEPLOY_ENV === "test")
        return AXIOS_TEST;

    if (process.env.REACT_APP_DEPLOY_ENV === "stage")
        return AXIOS_STAGE;

    if (process.env.REACT_APP_DEPLOY_ENV === "live")
        return AXIOS_LIVE;

    console.error("ERROR REACT_APP_DEPLOY_ENV is undefined");

    return null;
}

export const setupAxiosGlobals = () => {
    let config = getConfig();

    if (config === null) {
        console.error("ERROR setupAxiosGlobals failed!");
        return;
    }
    axios.defaults.headers = config.veu.headers; //post['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.baseURL = config.veu.baseUrl; // veu Backend API baseUrl
    axios.defaults.timeout = config.timeout;
}
