/*
 * ---------------------------------------------------------------------
 * Event Action Types
 * ---------------------------------------------------------------------
 */
export const SET_EVENT = "SET_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";

export const SET_STREAM = "SET_STREAM";
export const SET_CONFERENCE = "SET_CONFERENCE";
export const SET_CHANNEL = "SET_CHANNEL";
export const SET_CHANNEL_URL ="SET_CHANNEL_URL";

export const SET_GLOBALNOTICE ="SET_GLOBALNOTICE";
