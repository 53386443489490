import axios from "axios";

/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_PAGES_HOME,
    SET_PAGES_PREREGISTER,
    SET_PAGES_TILESMENU,
    SET_PAGE_GLOBAL,
    SHOW_PAGE_GLOBAL,
    HIDE_PAGE_GLOBAL,
    SET_PAGE_CONTENT,
} from "./types";

import { getConfig } from "src/config/axios";

import { hideContent } from "src/shared_modules/veu-visitors-core/actions/content";

import {
    setError,
} from "src/shared_modules/veu-visitors-core/actions/system";

// legal Page types
import { EVENT_PAGE_TYPE } from "src/shared_modules/veu-visitors-core/constants";

/*
 * ---------------------------------------------------------------------
 * internal Actions
 * ---------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------
 * Public Actions
 * ---------------------------------------------------------------------
 */
export const setHome = (val) => ({
    type: SET_PAGES_HOME,
    payload: val
});

export const setPreregister = (val) => ({
    type: SET_PAGES_PREREGISTER,
    payload: val
});

export const setTilesmenu = (val) => ({
    type: SET_PAGES_TILESMENU,
    payload: val
});

export const setPageGlobal = (val) => ({
    type: SET_PAGE_GLOBAL,
    payload: val
});
export const hidePageGlobal = () => ({
    type: HIDE_PAGE_GLOBAL
});
export const setPageContent = (val) => ({
    type: SET_PAGE_CONTENT,
    payload: val
});

/*
 * ---------------------------------------------------------------------
 * async thunks
 * ---------------------------------------------------------------------
 */

export const fetchPagesForType = (pagetype) => {
    return async (dispatch, getState) => {
        //console.log("fetchStartloc");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch(setError(null));
        const done = await axios.get("/visitors/page/type/" + pagetype, {
            // options
            crossDomain: true,
            headers: headers
        })
            .then((response) => {
                //console.log( JSON.stringify(response, null,2) );
                if (response.status === 200) {
                    let pages = response.data.pages;

                    if (pagetype === EVENT_PAGE_TYPE.HOME) {
                        dispatch(setHome(pages));
                    } else if (pagetype === EVENT_PAGE_TYPE.PREREGISTER) {
                        dispatch(setPreregister(pages));
                    }
                    else if (pagetype === EVENT_PAGE_TYPE.TILESMENU) {
                        dispatch(setTilesmenu(pages));
                    }
                }
            })
            .catch((error) => {
                dispatch(setError(error.response));
                //console.error( JSON.stringify(error,null,2));
            });
        return done;
    }
}


// fetch tilesmenu or event page
export const fetchPageForIdentifier = (indentifier) => {
    return async (dispatch, getState) => {
        //console.log("fetchStartloc");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch(setError(null));

        const done = await axios.get("/visitors/page/ident/" + indentifier, {
            // options
            crossDomain: true,
            headers: headers
        })
            .then((response) => {
                //console.log( JSON.stringify(response, null,2) );
                if (response.status === 200) {
                    let page = response.data.page;
                    if (page.type === EVENT_PAGE_TYPE.CONTENT) {
                        dispatch(setPageContent(page));
                    }
                    else if (page.type === EVENT_PAGE_TYPE.TILESMENU) {
                        dispatch(setTilesmenu(page));
                    }
                }
            })
            .catch((error) => {
                dispatch(setError(error.response));
                //console.error( JSON.stringify(error,null,2));
            });

        return done;
    }
}


export const fetchPageGlobalForIdentifier = (indentifier) => {
    return async (dispatch, getState) => {
        //console.log("fetchStartloc");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;
        dispatch(setError(null));

        const done = await axios.get("/visitors/page/global/" + indentifier, {
            // options
            crossDomain: true,
            headers: headers
        })
            .then((response) => {
                //console.log( JSON.stringify(response, null,2) );
                if (response.status === 200) {
                    let page = response.data.page;
                    dispatch(setPageGlobal(page));

                }
            })
            .catch((error) => {
                dispatch(setError(error.response));
                //console.error( JSON.stringify(error,null,2));
            });

        return done;
    }
}

export const showPageGlobal = (indentifier) => {
    return async (dispatch, getState) => {
        dispatch(hideContent());
        dispatch(setPageGlobal(null));
        dispatch({ type: SHOW_PAGE_GLOBAL });
        dispatch(fetchPageGlobalForIdentifier(indentifier));
    }
}
