/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
import {
    SET_PAGES_HOME,
    SET_PAGES_PREREGISTER,
    SET_PAGES_TILESMENU,
    SET_PAGE_GLOBAL,
    SHOW_PAGE_GLOBAL,
    HIDE_PAGE_GLOBAL,
    SET_PAGE_CONTENT,
} from "src/shared_modules/veu-visitors-core/actions/pages/types";

/*
 * ---------------------------------------------------------------------
 * initial system state
 * ---------------------------------------------------------------------
 */
const initialState = {
    pagesHome : null,
    pagesPreregister : null,
    pagesTilesmenu : null,
    pageGlobal : null,
    isGlobalOpen : false,
    pageContent : null,
};

/*
 * ---------------------------------------------------------------------
 * internal
 * ---------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------
 * pages reducer
 * ---------------------------------------------------------------------
 */
const pages = (state = initialState, action) => {
    switch (action.type) {
        case SET_PAGES_HOME:
            return Object.assign({}, state, {
                pagesHome: action.payload
            });
        case SET_PAGES_PREREGISTER:
            return Object.assign({}, state, {
                pagesPreregister: action.payload
            });
        case SET_PAGES_TILESMENU:
            return Object.assign({}, state, {
                pagesTilesmenu: action.payload
            });
        case SET_PAGE_GLOBAL:
            return Object.assign({}, state, {
                pageGlobal: action.payload
            });
        case SHOW_PAGE_GLOBAL:
            return Object.assign({}, state, {
                isGlobalOpen: true,
            });
        case HIDE_PAGE_GLOBAL:
            return Object.assign({}, state, {
                isGlobalOpen: false,
                pageGlobal: null,
            });
        case SET_PAGE_CONTENT:
            return Object.assign({}, state, {
                pageContent: action.payload
            });

        default:
            return state;
    }
};

export default pages;
