/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_MODERATOR_TOURS,
    OPEN_MODERATOR_TOURS,
    CLOSE_MODERATOR_TOURS,
    SET_ACTIVE_MODERATOR,
    SET_ACTIVE_TOUR,
    SET_DURATION,
    SET_AUTOPLAY,
    SET_PLAYING,
    SET_FINISHED_PLAYING,

} from "src/shared_modules/veu-visitors-core/actions/moderator/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    isOpen : false,

    activeTour : null,
    activeTourIndex: null,

    activeMod: null,
    activeModIndex: null,

    activeDuration: -1,

    tourList: null,

    isAutoplay: true,
    isPlaying: false,

    isFinishedPlaying: false,
};



/*
 * ---------------------------------------------------------------------
 *  reducer
 * ---------------------------------------------------------------------
 */
const moderator = (state = initialState, action) => {
    switch( action.type ) {
        case SET_MODERATOR_TOURS:
            return Object.assign({}, state, {
                tourList: action.payload
            });
        case OPEN_MODERATOR_TOURS:
            return Object.assign({}, state, {
                isOpen: true
            });
        case CLOSE_MODERATOR_TOURS:
            return Object.assign({}, state, {
                isOpen: false
            });
        case SET_ACTIVE_MODERATOR:
        return Object.assign({}, state, {
                activeMod: action.payload.activeMod,
                activeModIndex: action.payload.activeModIndex
            });
        case SET_ACTIVE_TOUR:
        return Object.assign({}, state, {
                activeTour: action.payload.activeTour,
                activeTourIndex: action.payload.activeTourIndex
            });
        case SET_DURATION:
            return Object.assign({}, state, {
                activeDuration: action.payload
            });
        case SET_AUTOPLAY:
            return Object.assign({}, state, {
                isAutoplay: action.payload
            });
        case SET_PLAYING:
            return Object.assign({}, state, {
                isPlaying: action.payload
            });
        case SET_FINISHED_PLAYING:
            return Object.assign({}, state, {
                isFinishedPlaying: action.payload
            });
        default:
            return state;
    }
};

export default moderator;
