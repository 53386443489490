/*
 * ---------------------------------------------------------------------
 * System Action Types
 * ---------------------------------------------------------------------
 */
export const SET_MODERATOR_TOURS = "SET_MODERATOR_TOURS";
export const OPEN_MODERATOR_TOURS = "OPEN_MODERATOR_TOURS";
export const CLOSE_MODERATOR_TOURS = "CLOSE_MODERATOR_TOURS";

export const SET_ACTIVE_MODERATOR = "SET_ACTIVE_MODERATOR";
export const SET_ACTIVE_TOUR = "SET_ACTIVE_TOUR";
export const SET_DURATION = "SET_DURATION";
export const SET_AUTOPLAY = "SET_AUTOPLAY";
export const SET_PLAYING = "SET_PLAYING";
export const SET_FINISHED_PLAYING = "SET_FINISHED_PLAYING";
