/**
 * ---------------------------------------------------------------------
 * language-list.js
 * ---------------------------------------------------------------------
 * @author seven-m.de
 * @copyright SEVEN M 2020
 *
 * ATTENTION:
 * shared_modules and frontend i18n contain an exact copy of this file!
 */

export const LANG_VERSION = "1.0.0";

/**
 * this list contains all supported languages
 *
 * @See https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 *
 * ATTENTION:
 * PhoeniCS works internal alway with locale codes!
*
 * locale = locale code (the locale code uses always the country where the language originates)
 * code2 = ISO 639-1 2-letter language code
 * code3 = ISO 639-2/B  3-letter language code
 * name = short name of the language in Englisch
 * native = name in native language
 * full = (optional) if not set name is used
 * fullNative = (optional) if not seet Native is used
 * @type {Array}
 */
export const LANGUAGES = [
    //
    { locale: "zh-CN", code2: "zh", code3 : "chi", name : "Chinese",    native : "中文", full : "Chinese (simplified)" }, // Chineses simplifed
    { locale: "cs-CZ", code2: "cs", code3 : "cze", name : "Czech",      native : "Čeština" },
    { locale: "da-DK", code2: "da", code3 : "dan", name : "Danish",     native : "Dansk" },
    { locale: "nl-NL", code2: "nl", code3 : "dut", name : "Dutch",      native : "Nederlands" },
    { locale: "en-GB", code2: "en", code3 : "eng", name : "English",    native : "English" },
    { locale: "fi-FI", code2: "fi", code3 : "fin", name : "Finnish",    native : "Suomi" },
    { locale: "fr-FR", code2: "fr", code3 : "fre", name : "French",     native : "Français" },
    { locale: "de-DE", code2: "de", code3 : "ger", name : "German",     native : "Deutsch" },
    { locale: "el-GR", code2: "el", code3 : "gre", name : "Greek",      native : "ελληνικά" },
    { locale: "hu-HU", code2: "hu", code3 : "hun", name : "Hungarian",  native : "Magyar" },
    { locale: "is-IS", code2: "is", code3 : "ice", name : "Icelandic",  native : "Íslenska" },
    { locale: "it-IT", code2: "it", code3 : "ita", name : "Italian",    native : "Italiano" },
    { locale: "ja-JP", code2: "ja", code3 : "jpn", name : "Japanese",   native : "日本語" },
    { locale: "ko-KR", code2: "ko", code3 : "kor", name : "Korean",     native : "한국어" },
    { locale: "no-NO", code2: "no", code3 : "nor", name : "Norwegian",  native : "Norsk" },
    { locale: "pl-PL", code2: "pl", code3 : "pol", name : "Polish",     native : "Polski" },
    { locale: "pt-PT", code2: "pt", code3 : "por", name : "Portuguese", native : "Português" },
    { locale: "ru-RU", code2: "ru", code3 : "rus", name : "Russian",    native : "Pусский" },
    { locale: "sk-SK", code2: "sk", code3 : "slo", name : "Slovak",     native : "Slovenčina" },
    { locale: "sl-SI", code2: "sl", code3 : "slv", name : "Slovenian",  native : "Slovenščina" },
    { locale: "es-ES", code2: "es", code3 : "spa", name : "Spanish",    native : "Español" },
    { locale: "sv-SE", code2: "sv", code3 : "swe", name : "Swedish",    native : "Svenska" },
    { locale: "tr-TR", code2: "tr", code3 : "tur", name : "Turkish",    native : "Türkçe" },
];
