/*
 * ---------------------------------------------------------------------
 * Action Types
 * ---------------------------------------------------------------------
 */
export const OPEN_PLAZZ_CHAT = "OPEN_PLAZZ_CHAT";
export const CLOSE_PLAZZ_CHAT = "CLOSE_PLAZZ_CHAT";
export const DISPOSE_PLAZZ_CHAT = "DISPOSE_PLAZZ_CHAT";

export const SET_PLAZZ_CHAT_CONVERSATIONS = "SET_CHAT_CONVERSATIONS";
export const SET_PLAZZ_CHAT_NEWMESSAGE = "SET_PLAZZ_CHAT_NEWMESSAGE";
export const UPDATE_PLAZZ_CHAT_CONVERSATION = "UPDATE_PLAZZ_CHAT_CONVERSATION";
export const UPDATE_PLAZZ_CHAT_CONVERSATION_PARTNER = "UPDATE_PLAZZ_CHAT_CONVERSATION_PARTNER";
export const SET_PLAZZ_CHAT_UNREAD_COUNT = "SET_PLAZZ_CHAT_UNREAD_COUNT";

export const SET_PLAZZ_CHAT_PERSONS = "SET_PLAZZ_CHAT_PERSONS";
export const SET_PLAZZ_CHAT_PERSON_ANCHOR = "SET_PLAZZ_CHAT_PERSON_ANCHOR";
export const SET_PLAZZ_CHAT_PERSON_FILTER = "SET_PLAZZ_CHAT_PERSON_FILTER";
