/*
 * ---------------------------------------------------------------------
 * User Action Types
 * ---------------------------------------------------------------------
 */
export const SET_USER = "SET_USER";

export const SET_PROFILE = "SET_PROFILE";

export const CLEAR_USER = "CLEAR_USER";

export const SET_USER_LOCATION = "SET_USER_LOCATION";

export const SET_PREREGISTERED = "SET_PREREGISTERED";

export const SET_KOMDAT_LEAD = "SET_KOMDAT_LEAD";

export const SET_KOMDAT_USERLIKEWASOPENED = "SET_KOMDAT_USERLIKEWASOPENED";

export const SET_MINIEVENTS_LOGGEDIN = "SET_MINIEVENTS_LOGGEDIN";

export const ADD_ALREADY_ACCESSED_SCENE = "ADD_ALREADY_ACCESSED_SCENE";
