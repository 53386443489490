/*
 * ---------------------------------------------------------------------
 * System Action Types
 * ---------------------------------------------------------------------
 */
export const SET_PAGES_HOME = "SET_PAGES_HOME";
export const SET_PAGES_PREREGISTER = "SET_PAGES_PREREGISTER";
export const SET_PAGES_TILESMENU = "SET_PAGES_TILESMENU";
export const SET_PAGE_GLOBAL = "SET_PAGE_GLOBAL";
export const SHOW_PAGE_GLOBAL = "SHOW_PAGE_GLOBAL";
export const HIDE_PAGE_GLOBAL = "HIDE_PAGE_GLOBAL";

export const SET_PAGE_CONTENT = "SET_PAGE_CONTENT";
