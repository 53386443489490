/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_MEACONFIG,
} from "../actions/mea/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    config: null,
};

/*
 * ---------------------------------------------------------------------
 * functions
 * ---------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------
 * mea reducer
 * ---------------------------------------------------------------------
 */
const mea = (state = initialState, action) => {
    switch( action.type ) {
        case SET_MEACONFIG:
            return Object.assign({}, state, {
                config: action.payload
            });

        default:
            return state;
    }
};

export default mea;
