/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SHOW_CONTENT,
    HIDE_CONTENT
} from "../actions/content/types";


/*
 * ---------------------------------------------------------------------
 * initial popovers state
 * ---------------------------------------------------------------------
 */
const initialState = {
    isOpen: false,
    type : "closed",
    url : null,
    size : "lg",
};
/*
 * ---------------------------------------------------------------------
 * popovers reducer
 * ---------------------------------------------------------------------
 */
const popovers = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CONTENT:
            return Object.assign({}, state, {
                isOpen: true,
                type: action.payload.type,
                url: action.payload.url,
                size: action.payload.size,
            });

        case HIDE_CONTENT:
            return Object.assign({}, state, initialState);

        default:
             return state;
    }
};

export default popovers;
