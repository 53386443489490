/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    OPEN_PLAZZ_CHAT,
    CLOSE_PLAZZ_CHAT,
    DISPOSE_PLAZZ_CHAT,

    SET_PLAZZ_CHAT_CONVERSATIONS,
    SET_PLAZZ_CHAT_PERSONS,
    SET_PLAZZ_CHAT_NEWMESSAGE,
    SET_PLAZZ_CHAT_UNREAD_COUNT,
    SET_PLAZZ_CHAT_PERSON_ANCHOR,
    SET_PLAZZ_CHAT_PERSON_FILTER,

    UPDATE_PLAZZ_CHAT_CONVERSATION,
    UPDATE_PLAZZ_CHAT_CONVERSATION_PARTNER,
} from "../actions/chat/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    // ui
    isOpen : false,
    activeTab : "persons", // persons or conversations

    // chats
    hasNewMessage : false,
    messageCount : 0,
    activeConversation: null, // id
    conversations : [], // all conversations (overview) updated per timeout
    fullConversations : {}, // all conversations with all messages but updated on demand
    conversationPartners : {}, // here are the infos for partners of a chat
    unreadCount : "",
    conversationSearch : "",

    //visitors and persons
    activePerson : null,    // id
    persons : [],   // all persons (overview)
    personAnchor : "",
    personFilter : "location",
    personSearch : "",
};

/*
 * ---------------------------------------------------------------------
 * functions
 * ---------------------------------------------------------------------
 */
const updateConversationsPartners = ( state, action ) => {
    let conpartners = state.conversationPartners;
    // already there update or a new one
    conpartners[action.payload.id] = action.payload;

    return Object.assign({}, state, {
            conversationPartners: conpartners
        });
 }


const updateConversation = ( state, action ) => {
    let conv = state.fullConversations;

    conv[action.payload.partnerid] = action.payload.messages

    return Object.assign({}, state, {
            fullConversations: conv
        });
}

const openChat = ( state, action ) =>  {

    let changes = {
        isOpen : true,
        activeTab :  action.payload.tab,
        activePerson : null,
        activeConversation : null,
    }

    if( changes.activeTab === "persons" ) {
        changes.activePerson =  action.payload.active;
    } else {
        changes.activeConversation =  action.payload.active;
    }
    return Object.assign({}, state, changes );
}

const disposeChatStates = ( state ) => {
    return Object.assign({}, state, initialState );
}

/*
 * ---------------------------------------------------------------------
 * Plazz reducer
 * ---------------------------------------------------------------------
 */
const chat = (state = initialState, action) => {

    switch( action.type ) {
        case OPEN_PLAZZ_CHAT:
            return openChat(state, action);
        case CLOSE_PLAZZ_CHAT:
            return Object.assign({}, state, {
                    isOpen: false
                });
        case DISPOSE_PLAZZ_CHAT:
            return disposeChatStates(state);

        case SET_PLAZZ_CHAT_CONVERSATIONS:
            return Object.assign({}, state, {
                    conversations: action.payload
                });
        case SET_PLAZZ_CHAT_PERSONS:
            return Object.assign({}, state, {
                    persons: action.payload
                });
        case SET_PLAZZ_CHAT_NEWMESSAGE:
            return Object.assign({}, state, {
                    hasNewMessage: action.payload.hasNewMessage,
                    messageCount: action.payload.messageCount
                });
        case UPDATE_PLAZZ_CHAT_CONVERSATION:
            return updateConversation(state, action);
        case UPDATE_PLAZZ_CHAT_CONVERSATION_PARTNER:
            return updateConversationsPartners(state, action);
        case SET_PLAZZ_CHAT_PERSON_ANCHOR:
            return Object.assign({}, state, {
                    personAnchor: action.payload
                });
        case SET_PLAZZ_CHAT_PERSON_FILTER:
            return Object.assign({}, state, {
                    personFilter: action.payload
                });
        case SET_PLAZZ_CHAT_UNREAD_COUNT:
            return Object.assign({}, state, {
                    unreadCount: action.payload
                });

        default:
            return state;
    }
};

export default chat;
