/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { logger } from "redux-logger";
import rootReducer from "./reducers";
/*
 * ---------------------------------------------------------------------
 * create REDUX store
 * ---------------------------------------------------------------------
 */

const configureStore = () =>{
    let store = null;
    if( process.env.REACT_APP_DEPLOY_ENV === 'live' ) {
        // no redux logger in live
        store = createStore(
                rootReducer,
                applyMiddleware(
                    thunk
                )
            );
    } else {
        // add redux logger
        store = createStore(
                rootReducer,
                applyMiddleware(
                    thunk,
                    // logger must be always the last in chain!
                    logger
                )
            );
    }

    return store;
}

const store = configureStore();

export default store;
