/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SHOW_POPOVER,
    HIDE_POPOVER,
    HIDE_ALL_POPOVERS,
    PRESET_LOGIN_HINT,
} from "./types";

import {
    hideContent
} from "src/shared_modules/veu-visitors-core/actions/content";


const internalShowPopover = ( popover, options ) => ({
    type: SHOW_POPOVER,
    payload: {
         popover : popover,
         options : options,
    }
});

/*
 * ---------------------------------------------------------------------
 * Public Popover Actions
 * ---------------------------------------------------------------------
 */

export const hidePopover = ( popover, options={} ) => ({
    type: HIDE_POPOVER,
    payload: {
         popover : popover,
         options : options,
    }
});

export const hideAllPopovers = () => ({
    type: HIDE_ALL_POPOVERS
});

export const showPopover = ( popover, options={} ) => {
    return async ( dispatch, getState ) => {
        dispatch( hideContent());

        // TODO Close chat close, all popovers,
        dispatch( internalShowPopover(popover, options));
    }
};

export const presetLoginHint = (hint) => ({
    type: PRESET_LOGIN_HINT,
    payload: hint,
});
