/*
 * ---------------------------------------------------------------------
 * Popovers Action Types
 * ---------------------------------------------------------------------
 */
export const SHOW_POPOVER = "SHOW_POPOVER";

export const HIDE_POPOVER = "HIDE_POPOVER";

export const HIDE_ALL_POPOVERS = "HIDE_ALL_POPOVERS";

export const PRESET_LOGIN_HINT = "PRESET_LOGIN_HINT";
