import axios from "axios";

/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_MEACONFIG,
} from "./types";

import {
    setError
} from "src/shared_modules/veu-visitors-core/actions/system";

import { getConfig } from "src/config/axios";
/*
 * ---------------------------------------------------------------------
 * Public Actions
 * ---------------------------------------------------------------------
 */
export const setMeaConfig = ( conf ) => ({
    type: SET_MEACONFIG,
    payload: conf
});
/*
 * ---------------------------------------------------------------------
 * async thunks
 * ---------------------------------------------------------------------
 */
export const fetchMeaConfig = ( ) => {
    return async ( dispatch, getState ) => {
        //console.log("fetchMeaConfig " );
        let axiosconf = getConfig().veu;
        let url = "/visitors/plazz/meaconfig";
        let headers = axiosconf.headers;

        dispatch( setError(null) );

        const done = await axios.get(url, {
            // options
            crossDomain: true,
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                dispatch( setMeaConfig(response.data) );
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            //console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}
