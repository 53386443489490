/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SHOW_POPOVER,
    HIDE_POPOVER,
    HIDE_ALL_POPOVERS,
    PRESET_LOGIN_HINT
} from "src/shared_modules/veu-visitors-core/actions/popovers/types";


/*
 * ---------------------------------------------------------------------
 * initial popovers state
 * ---------------------------------------------------------------------
 */
const initialState = {
    // user single popups
    showLogin: false,
    // if not null shows an hint inside the login
    loginHint : null,

    showRegister : false,
    showRegisterSuccess: false,
    showForgot : false,
    // user combined popups single or user-tab-container
    showPrivacy : false,
    showProfile : false,
    showAccount : false,
    // if true the above popups ar showwn inside the-user-tab-container
    openInUserTabContainer : false,
    // popover show if you try to enter a room you have no access
    showAccessDenied : false,

// TODO all reminder become snacks
// TODO Temporary workaround
    showReminderConference: false,
// TODO move to seperate stream store!!
    showReminderStream: false,
    showReminderStreamOnAir: false,
    showAppointments: false,

//TODO  not implemented yet
    showSettings : false,
    showMiniEventLogin : false,
};

const doShowPopover = ( state, action ) => {
    let obj = {};

    if( action.payload.options.openInUserTabContainer ) {
        obj["openInUserTabContainer"] = action.payload.options.openInUserTabContainer;
        if( action.payload.options.openInUserTabContainer === true ) {
            //hide other user popovers
            obj["showProfile"] = false;
            obj["showPrivacy"] = false;
            obj["showAccount"] = false;
        }
    }
    obj["show" + action.payload.popover] = true;

    if( action.payload.options && action.payload.options.hint && action.payload.popover === "Login" ) {
        obj["loginHint"] = action.payload.options.hint;
    }
    return Object.assign({}, state, obj);
}

const doHidePopover = ( state, action ) => {
    let obj = {};
    obj["show" + action.payload.popover] = false;

    if( action.payload.options.openInUserTabContainer ) {
        obj["openInUserTabContainer"] = action.payload.options.openInUserTabContainer;
    }

    // delete hints
    obj["loginHint"] = null;

    return Object.assign({}, state, obj);
}

/*
 * ---------------------------------------------------------------------
 * popovers reducer
 * ---------------------------------------------------------------------
 */
const popovers = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_POPOVER:
            return doShowPopover(state, action);

        case HIDE_POPOVER:
            return doHidePopover(state, action);

        case HIDE_ALL_POPOVERS:
            return Object.assign({}, state, initialState);

        case PRESET_LOGIN_HINT:
            return Object.assign({}, state, {
                loginHint: action.payload
            });


        default:
             return state;
    }
};

export default popovers;
