/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_APPOINTMENT_LIST,
    SET_APPOINTMENT_IDENTIFIER,
} from "../actions/appointments/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    list: null,
    identifier: null,
};
/*
 * ---------------------------------------------------------------------
 *  reducer
 * ---------------------------------------------------------------------
 */
const appointments = (state = initialState, action) => {
    switch( action.type ) {
        case SET_APPOINTMENT_LIST:
            return Object.assign({}, state, {
                list: action.payload
            });
        case SET_APPOINTMENT_IDENTIFIER:
            return Object.assign({}, state, {
                identifier: action.payload
            });

        default:
            return state;
    }
};

export default appointments;
