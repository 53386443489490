import React from 'react';
import './loader.css';

import { useTheme } from '@material-ui/core/styles';


function LoadingOverlay() {
    const theme = useTheme();

    //set the variable for our css!
    const themedStyle = {
        "--themeSecondaryMain": theme.palette.secondary.main
    }
    
    return (<aside id="loader-overlay" style={themedStyle}>
        	 	<div className="loader" style={themedStyle}>Loading...</div>
        	  </aside>);

}

export default LoadingOverlay;
