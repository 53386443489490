/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_PLAZZ_ACTIVE_POLL,
    SET_PLAZZ_VOTING,
    SET_PLAZZ_POLL_PARTICIPATED,
} from "../actions/poll/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    // contains poll identifiers of all participated polls
    participated: [],
    // stores the active Poll, depends on stream and event
    // stored in our DB with plazz reference id
    activePoll : null,
    // from plazz
    plazzVoting: null,
};


const addParticipation = ( state, action ) => {
    let participated = state.participated;

    if( !participated.includes(action.payload) ) {
        participated.push(action.payload);
    }

    return Object.assign({}, state, participated);
}
/*
 * ---------------------------------------------------------------------
 * Plazz reducer
 * ---------------------------------------------------------------------
 */
const poll = (state = initialState, action) => {
    switch( action.type ) {
        case SET_PLAZZ_POLL_PARTICIPATED:
            return addParticipation(state, action);
        case SET_PLAZZ_VOTING:
            return Object.assign({}, state, {
                plazzVoting: action.payload
            });
        case SET_PLAZZ_ACTIVE_POLL:
            return Object.assign({}, state, {
                activePoll: action.payload
            });

        default:
            return state;
    }
};

export default poll;
