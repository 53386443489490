 /*
 * ---------------------------------------------------------------------
 * reducers index.js
 * ---------------------------------------------------------------------
 * @author seven-m.de
 * @copyright SEVEN M 2020
 *
 * The combinde reducer to bundle plazz
 */
/*
 * ---------------------------------------------------------------------
 * import modules
 * ---------------------------------------------------------------------
 */
import { combineReducers } from "redux";

/*
 * ---------------------------------------------------------------------
 * import reducers
 * ---------------------------------------------------------------------
 */
import mea from "./mea";
import chat from "./chat";
import poll from "./poll";
import woi from "./wall-of-ideas";


/*
 * ---------------------------------------------------------------------
 * plazz REDUX reducer combine all other reducers here
 * ---------------------------------------------------------------------
 */
export default combineReducers({
        mea,
        chat,
        poll,
        woi,
    });
