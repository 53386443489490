/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_TOKEN,
    SET_TOKEN_VALID,
} from "../actions/token/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    tokenId: null,
    isTokenValid: false,
};
/*
 * ---------------------------------------------------------------------
 *  reducer
 * ---------------------------------------------------------------------
 */
const token = (state = initialState, action) => {
    switch( action.type ) {
        case SET_TOKEN:
            return Object.assign({}, state, {
                tokenId: action.payload
            });
        case SET_TOKEN_VALID:
            return Object.assign({}, state, {
                isTokenValid: action.payload
            });

        default:
            return state;
    }
};

export default token;
