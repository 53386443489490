 /*
 * ---------------------------------------------------------------------
 * reducers index.js
 * ---------------------------------------------------------------------
 * @author seven-m.de
 * @copyright SEVEN M 2020
 *
 * This file combines them all for our root redux store.
 */
/*
 * ---------------------------------------------------------------------
 * import modules
 * ---------------------------------------------------------------------
 */
import { combineReducers } from "redux";

/*
 * ---------------------------------------------------------------------
 * import reducers
 * ---------------------------------------------------------------------
 */
// veu core reducers
import system from "src/shared_modules/veu-visitors-core/store/system";
import user from "src/shared_modules/veu-visitors-core/store/user";
import event from "src/shared_modules/veu-visitors-core/store/event";
import pages from "src/shared_modules/veu-visitors-core/store/pages";

import popovers from "src/shared_modules/veu-visitors-core/store/popovers";
import content from "src/shared_modules/veu-visitors-core/store/content";
import player from "src/shared_modules/veu-visitors-core/store/player";
import moderator from "src/shared_modules/veu-visitors-core/store/moderator";
import stream from "src/shared_modules/veu-visitors-core/store/stream";
import agenda from "src/shared_modules/veu-visitors-core/store/agenda";
import mentimeter from "src/shared_modules/veu-visitors-core/store/mentimeter";
import appointments from "src/shared_modules/veu-visitors-core/store/appointments";
import chatUserlike from "src/shared_modules/veu-visitors-core/store/chat-userlike";

import token from "src/shared_modules/veu-visitors-core/store/token";
import minieventAccess from "src/shared_modules/veu-visitors-core/store/minievent-access";
// 3rd party reducers (always optional)
// plazz
import plazz from "src/shared_modules/veu-visitors-plazz/store";

// TODOs
// moderator

/*
 * ---------------------------------------------------------------------
 * root REDUX reducer combine all other reducers here
 * ---------------------------------------------------------------------
 */
export default combineReducers({
        system,
        user,
        event,
        pages,
        popovers,
        content,
        player,
        moderator,
        plazz,
        stream,
        agenda,
		mentimeter,
        appointments,
        chatUserlike,
        token,
        minieventAccess,
    });
