import axios from "axios";
/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_EVENT,
    UPDATE_EVENT,
    SET_CONFERENCE,
    SET_CHANNEL_URL,
    SET_CHANNEL,
    SET_GLOBALNOTICE,
} from "./types";

import {
    setError,
    setUserCount,
    setAppState,
} from "src/shared_modules/veu-visitors-core/actions/system";

import { getConfig } from "src/config/axios";

/*
 * ---------------------------------------------------------------------
 * internal Actions
 * ---------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------
 * Public Actions
 * ---------------------------------------------------------------------
 */

export const setEvent = (ev) => ({
    type: SET_EVENT,
    payload: ev
});

export const updateEvent = (ev) => ({
    type: UPDATE_EVENT,
    payload: ev
});

export const setLiveConference = (conference) => ({
    type: SET_CONFERENCE,
    payload: conference
});

export const setChannelUrl = (url) => ({
    type: SET_CHANNEL_URL,
    payload: url
});

export const setGlobalNotice = (globalnotice) => ({
    type: SET_GLOBALNOTICE,
    payload: globalnotice
});

export const setLiveConferenceChannel = (channel) => ({
    type: SET_CHANNEL,
    payload: channel
});

/**
 * fetches the event by its path id from backend
 * this is only called during the app loading phases
 *
 * @param  {string} id the unique path id
 * @return {[type]}    [description]
 */
export const fetchEvent = (id) => {
    return async (dispatch, getState) => {
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        const done = await axios.get("/visitors/event/" + id,
            {
                // options
                crossDomain: true,
                headers: headers
            }
        )
            .then((response) => {
                if (response.status === 200) {
                    dispatch(setEvent(response.data));
                }
            })
            .catch((error) => {
                dispatch(setError(error.response));
                console.error(JSON.stringify(error.response, null, 2));
            });
        return done;
    }
}



export const fetchChannelUrl = () => {
    return async (dispatch, getState) => {
        //console.log("fetchConferenceUrl");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch(setError(null));


        const done = await axios.get("/visitors/liveconferences/channelUrl", {
            // options
            crossDomain: true,
            headers: headers
        })
            .then((response) => {
                if (response.status === 200) {
                    //if (response.data.channelUrl) {
                        dispatch(setChannelUrl(response.data.channelUrl));
                        return;
                    //}
                }
                dispatch(setError(response.status));
            })
            .catch((error) => {
                dispatch(setError(error.response));
                //console.error( JSON.stringify(error,null,2));
            });

        return done;
    }
}

export const clearChannelUrl = () => {
    return async (dispatch, getState) => {
        //console.log("clearChannelUrl");

        dispatch(setChannelUrl(null));
        return;
    }
}

/*export const fetchLiveConference = ( ) => {
  console.log("------------------------------------fetchLiveConference");
    return async ( dispatch, getState ) => {
        //console.log("fetchLiveConference");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch( setError(null) );

        const done = await axios.get("/liveconferences", {
            // options
            crossDomain: true,
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if (response.status === 200) {
                //has always to be set - also to null - becaus in App.js we check for it and we need to "reset" the conference if there is no conference on Air and no next conference
                //if (response.data.conference) {
                dispatch( setLiveConference(response.data.conference) );
                //}
                return;
            }
            dispatch( setError( response.status ) );
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            //console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}*/



// export const fetchGlobalNotice = ( ) => {
//     return async ( dispatch, getState ) => {
//         console.log("fetchGlobalNotice");
//         let axiosconf = getConfig().veu;
//         let headers = axiosconf.headers;
//         headers["VEU-EVENT"] = getState().event.path;

//         dispatch( setError(null) );

//         const done = await axios.get("/globalnotices", {
//             // options
//             crossDomain: true,
//             headers: headers
//         })
//         .then( (response) => {
//             //console.log( JSON.stringify(response, null,2) );
//             if (response.status === 200) {
//                 dispatch( setGlobalNotice(response.data) );
//                 //console.log("fetchGlobalNoticeSuccsess "+JSON.stringify(response.data));
//                 return;
//             }
//             dispatch( setError( response.status ) );
//         })
//         .catch( (error) => {
//             dispatch( setError( error.response ) );
//             //console.error( JSON.stringify(error,null,2));
//         });
//         return done;
//     }
// }

/**
 * fetchRefreshAll
 *
 * fetches all event specific optional modules for updating all in one call
 * including optional event modules.
 *
 * called if needed after defined timeout see App.js
 *
 * @return {[type]} [description]
 */
export const fetchRefreshAll = () => {
    return async (dispatch, getState) => {
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch(setError(null));

        const done = await axios.get("/visitors/refreshAll", {
            // options
            crossDomain: true,
            headers: headers
        })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateEvent(response.data.event));

                    if (response.data.event.hasGlobalNotice) {
                        dispatch(setGlobalNotice(response.data.event.globalNotice));
                    }

                    if (response.data.event.hasConference) {
                        dispatch(setLiveConference(response.data.event.liveConference));
                    }

                    if (response.data.usercount)
                        dispatch(setUserCount(response.data.usercount));

                    return;
                } else {
                    dispatch(setAppState("internal-error"));
                    dispatch(setError(response.status));
                }
            })
            .catch((error) => {
                dispatch(setAppState("internal-error"));
                dispatch(setError(error.response));
            });
        return done;
    }
};
