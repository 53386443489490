/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_MINIEVENT_CODE_VALID,
} from "../actions/minievent-access/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    isCodeValid: false,
};
/*
 * ---------------------------------------------------------------------
 *  reducer
 * ---------------------------------------------------------------------
 */
const minieventAccess = (state = initialState, action) => {
    switch( action.type ) {
        case SET_MINIEVENT_CODE_VALID:
            return Object.assign({}, state, {
                isCodeValid: action.payload
            });

        default:
            return state;
    }
};

export default minieventAccess;
