/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_MENTIMETER,
} from "../actions/mentimeter/types";

/*
 * ---------------------------------------------------------------------
 * initial event state
 * ---------------------------------------------------------------------
 */
// Till now the event is null maybe in future we change this behaviour
const initialState = {
    // event core features mandatory
    data: null,
};


/*
 * ---------------------------------------------------------------------
 * stream reducer
 * ---------------------------------------------------------------------
 */
const mentimeter = (state = initialState, action) => {
    switch (action.type) {
        case SET_MENTIMETER:
            return Object.assign({}, state, {
                data: action.payload,
            });

        default:
            return state;
    }
};

export default mentimeter;
