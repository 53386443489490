/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SHOW_WALLOFIDEAS,
    HIDE_WALLOFIDEAS,
    SET_PLAZZ_ACTIVE_WALL,
    SET_PLAZZ_WALLOFIDEAS_POSTS,
} from "../actions/wall-of-ideas/types";

/*
 * ---------------------------------------------------------------------
 * initial plazz state
 * ---------------------------------------------------------------------
 */
const initialState = {
    isOpen : false,         // used only by the hotspot windows, not by stream tabs!
    activeWall : null,
    posts: null,
};
/*
 * ---------------------------------------------------------------------
 *  reducer
 * ---------------------------------------------------------------------
 */
const woi = (state = initialState, action) => {
    switch( action.type ) {
        case SET_PLAZZ_ACTIVE_WALL:
            return Object.assign({}, state, {
                activeWall: action.payload
            });
        case SET_PLAZZ_WALLOFIDEAS_POSTS:
            return Object.assign({}, state, {
                posts: action.payload
            });
        case SHOW_WALLOFIDEAS:
            return Object.assign({}, state, {
                isOpen: true
            });
        case HIDE_WALLOFIDEAS:
            return Object.assign({}, state, {
                isOpen: false
            });

        default:
            return state;
    }
};

export default woi;
