import axios from "axios";

/*
 * ---------------------------------------------------------------------
 * import Action types
 * ---------------------------------------------------------------------
 */
import {
    SET_LOADING,
    SET_ERROR,
    SET_REDIRECT,
    CLEAR_REDIRECT,
    SET_SCENE,
    SET_STARTLOC,
    SET_APP_STATE,
    SET_CDN,
    SET_USERCOUNT,
    SET_LANG,
    ADD_SCENEPATH_TO_HISTORY,
} from "./types";


import { getConfig } from "src/config/axios";
/*
 * ---------------------------------------------------------------------
 * internal Actions
 * ---------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------
 * Public Actions
 * ---------------------------------------------------------------------
 */
export const setLoading = (val) => ({
    type: SET_LOADING,
    isLoading: val
});

export const setError = (val) => ({
    type: SET_ERROR,
    error: val
});

export const redirectTo = (to) => ({
    type: SET_REDIRECT,
    to: to
});

export const clearRedirect = () => ({
    type: CLEAR_REDIRECT
});

export const setScene = (scene) => ({
    type: SET_SCENE,
    payload: scene
});

export const setStartloc = (startloc) => ({
    type: SET_STARTLOC,
    payload: startloc
});

export const setAppState = (state) => ({
    type: SET_APP_STATE,
    payload: state
});
export const setCDN = (cdn) => ({
    type: SET_CDN,
    payload: cdn
});
export const setUserCount = (counts) => ({
    type: SET_USERCOUNT,
    payload: counts
});

export const setCurrentLanguage = ( locale ) => ({
    type: SET_LANG,
    payload: locale
});

export const addScenepathToHistory = (path) => ({
    type: ADD_SCENEPATH_TO_HISTORY,
    payload: {
        path : path
    }
});

/*
 * ---------------------------------------------------------------------
 * async thunks
 * ---------------------------------------------------------------------
 */

export const fetchCDN = ( ) => {
    return async ( dispatch, getState ) => {
        console.log("fetchCDN " );
        let axiosconf = getConfig().veu;
        let url = "/cdn/";
        let headers = axiosconf.headers;

        dispatch( setError(null) );

        const done = await axios.get(url, {
            // options
            crossDomain: true,
            headers: headers
        })
        .then( (response) => {
            if (response.status === 200) {
                dispatch( setCDN(response.data) );
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            //console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}

export const fetchSceneByScenename = ( scenename ) => {
    return async ( dispatch, getState ) => {
        //console.log("fetchSceneByScenename");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch( setError(null) );

        const done = await axios.get("/visitors/scenes/scene/" +scenename, {
            // options
            crossDomain: true,
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                dispatch( setScene(response.data.scene) );
            } else if( response.status === 401 || response.status === 500 ) {
                // Unauthorized or internal error
                dispatch( setError( response.message ) );
                //dispatch( setScene(null) );
            }
        })
        .catch( (error) => {

            dispatch( setError( error.response ) );
          //  console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}

export const fetchSceneById = ( sceneId ) => {
    return async ( dispatch, getState ) => {
        //console.log("fetchSceneById");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch( setError(null) );

        const done = await axios.get("/visitors/scenes/sceneId/" +sceneId, {
            // options
            crossDomain: true,
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                dispatch( setScene(response.data.scene) );

            } else if( response.status === 401 || response.status === 500 ) {
                // Unauthorized or internal error
                dispatch( setError( response.message ) );
                dispatch( setScene(null) );
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            //console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}

export const fetchStartloc = () => {
    return async ( dispatch, getState ) => {
        //console.log("fetchStartloc");
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch( setError(null) );
        const done = await axios.get("/visitors/scenes/startloc", {
            // options
            crossDomain: true,
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if (response.status === 200) {
                dispatch( setStartloc(response.data) );
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            //console.error( JSON.stringify(error,null,2));
        });
        return done;
    }
}
