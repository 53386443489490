/*
 * ---------------------------------------------------------------------
 * imports
 * ---------------------------------------------------------------------
 */
import axios from "axios";

import {
    redirectTo,
    setError,
    setStartloc,
} from "src/shared_modules/veu-visitors-core/actions/system";

import {
    setUser,
} from "src/shared_modules/veu-visitors-core/actions/user";

import {
    presetLoginHint,
} from 'src/shared_modules/veu-visitors-core/actions/popovers';

import { getConfig } from "src/config/axios";

/*
 * ---------------------------------------------------------------------
 * async thunks
 * ---------------------------------------------------------------------
 */

/**
 * doSSOByPlazzSession async thunk dispatch
 *
 * used if user is redirected from plazz confernece app and the session is transfered
 *
 * @param  {[type]} session_param [description]
 * @return {[type]}               [description]
 */
export const doSSOByPlazzLink = ( session_param ) => {
    return async ( dispatch, getState ) => {
        let ev = getState().event.path;
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        let data = { session: session_param };
        let done = await axios.post( "/account/plazz/directlink",
            data,
            {
                crossDomain: true,
                // options
                headers: headers
        })
        .then( (response) => {
            if( response.status === 200 ) {
                if( response.data.user ) {
                    dispatch(setUser(response.data.user ));
                }

                let redirect = ev;
                if( response.data.redirect ) {
                    redirect = redirect + response.data.redirect.path;
                    dispatch( setStartloc( response.data.redirect ) )
                }
                dispatch( redirectTo( redirect ) );
            }
        })
        .catch( (error) => {
            // ToDo: hier müsste aus Data der public Einstiegspunkt geholt werden
            // wenn das nicht funzt, statt irgendwas /home als letze Bastion
            let redirect = ev + "/home";
            console.error("plazz direktlink failed");
            console.error( JSON.stringify(error,null,2));

            dispatch( setError(error.response) );
            dispatch( redirectTo(  redirect ) );
        });

        return done;
    }
}
/**
 * doPlazzAuth
 *
 * plazz redirect authoraziation for Schaeffler SSO or social logins
 *
 * @param  {[type]} provider     [description]
 * @param  {[type]} refreshToken [description]
 * @return {[type]}              [description]
 */
export const doPlazzAuth = ( provider, refreshToken ) => {
    return async ( dispatch, getState ) => {
        let ev = getState().event.path;
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = ev;

        let data = { refreshToken: refreshToken };
        let done = await axios.post( "/account/plazz/"+provider,
            data,
            {
                crossDomain: true,
                // options
                headers: headers
        })
        .then( (response) => {
            if( response.status === 200 ) {
                if( response.data.user ) {
                    dispatch( setUser( response.data.user ) );
                }

                let redirect = ev;
                if( response.data.redirect ) {
                    redirect = redirect + response.data.redirect.path;
                    dispatch( setStartloc( response.data.redirect ) );
                }

                dispatch( redirectTo( redirect ) );
            }
        })
        .catch( (error) => {
            let redirect = ev + getState().system.startloc.path;
            console.error("plazz auth with provider failed: " + provider);
            console.error( JSON.stringify(error,null,2));
            if( provider === "schaeffler" ) {
                dispatch( presetLoginHint( "sso-failed" ) );
            }
            if( provider === "linkedin" ) {
                dispatch( presetLoginHint( "linkedin-failed" ) );
            }

            dispatch( setError(error.response) );
            dispatch( redirectTo( redirect ) );
        });

        return done;
    }
}
/**
 * updatePlazzPrivacy
 *
 * updates plazz privacy settings
 *
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
export const updatePlazzPrivacy = ( data ) => {
    return async ( dispatch, getState ) => {
        //console.log("updateLocation: " + path + "/" + room);
        let axiosconf = getConfig().veu;
        let headers = axiosconf.headers;
        headers["VEU-EVENT"] = getState().event.path;

        dispatch( setError(null) );

        await axios.post("/account/updatePrivacy", data, {
            crossDomain: true,
            // options
            headers: headers
        })
        .then( (response) => {
            //console.log( JSON.stringify(response, null,2) );
            if( response.status === 200 ) {
                if( response.data.user )
                    dispatch( setUser(response.data.user) );
            }
        })
        .catch( (error) => {
            dispatch( setError( error.response ) );
            console.error( JSON.stringify(error,null,2));
        });

        return null;
    }

}
