import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//don't need to specify localhost url in axios http address
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AppLoader from './AppLoader';
import { Provider } from 'react-redux';
import store from './store';
import { setupAxiosGlobals } from "./config/axios";
import * as serviceWorker from './serviceWorker';

// this is the best place to init axios! right before anything else...
setupAxiosGlobals();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Provider store={store}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/fallback" />
                    </Route>
                    <Route path="/:event">
                        <AppLoader />
                    </Route>
                </Switch>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// default react service worker
serviceWorker.unregister();
